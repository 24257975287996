<template>
	<ul class="table-box" :style="{borderColor: boderColor}">
		<li class="flex" :style="{borderColor: boderColor}" v-for="(item,index) in data" :key="index">
        <div class="table-title-box"
             :style="{height: item.unfold ? getNum(item.children.length) * 50 + 'px': '',lineHeight: item.unfold ? getNum(item.children.length) * 50 + 'px': '50px'}">
          <span>{{item.title}}</span>
        </div>
        <!-- 内容盒子 -->
        <div class="flex-1 rows">
          <!-- // 行 -->
          <template v-for="(columns,i) in getNum(item.children.length)">
            <div class="flex-1 flex" :key="i" v-if="i === 0 || item.unfold">
              <!-- 内容行 -->
              <ul class="gird table-grid flex-1" :style="getGrid">
                <!-- 列 -->
                <li :class="['columns', v.active ? 'pitch-on' : '']"
                    @click="handleCheck(v,index,i*rowNumber + n)" v-for="(v,n) in item.children.slice(i*rowNumber,(i + 1) * rowNumber)" :key="n">
                  {{v.categoryName}}
                </li>
              </ul>
              <div @click="handleMore(item,index)" class="more-btn">
                <button v-if="i===0 && getNum(item.children.length) > 1">更多 <span class="iconfont icon-xiangxia" v-show="!item.unfold"></span>
                  <span class="iconfont icon-xiangshang" v-show="item.unfold"></span></button>
              </div>
            </div>
          </template>
        </div>
		</li>
	</ul>
</template>
<script>
/**
 * @ 产品选型分类筛选
 * data 【array】 分类数据
 * rowNumber 【Number,String】 列数
 */
	export default {
		props: {
			data: {
				type: Array,
				default () {
					return [];
				}
			},
			rowNumber: {
				type: [Number, String],
				default: 7
			},
		},
		data() {
			return {
				lists: [], // 过滤后的数据
				pitchOn: '', // 当前选中
			};
		},
		watch:{
			data(v){

			}
		},
		computed: {
			getGrid() {
				let width = (100 / this.rowNumber).toFixed(2);
				return {
					gridTemplateColumns: `repeat(${this.rowNumber}, ${width}%)`
				};
			}
		},
		methods: {
			handleCheck(v,index,idx) { //选择的点击事件
				this.$emit("onChange", v,index,idx);
			},
			handleMore(v) {
				v.unfold = !v.unfold;
				this.$emit("onMore", v);
			},
			getNum(len){
				return Math.ceil(len/this.rowNumber);
			},
		},
		created() {

		}
	};
</script>
<style lang="less" scoped>
	.flex {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.flex-1 {
		flex: 1;
	}

	.gird {
		display: grid;
	}

	// 盒子样式
	.table-box {
		border: 1px solid #cccccc;
		.table-title-box {
			display: block;
			height: 50px;
			line-height: 50px;
			padding: 0 45px;
			border-right: 1px solid #cccccc;
			border-bottom: 1px solid #cccccc;
			font-size: 14px;
			color: #666;
			font-weight: bold;
			background-color: #f3f3f3;
		}
		.rows {
			background-color: #fff;
			// padding-right: 20px;
			box-sizing: border-box;
		}
		.rows>div {
			padding-right: 20px;
			box-sizing: border-box;
			border-bottom: 1px solid #cccccc;
			font-size: 14px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #003984;
			.more-btn {
				width: 62px;
				height: 25px;
				margin-left: 150px;
				button {
					font-size: 14px;
					width: 100%;
					height: 100%;
					width: 62px;
					height: 25px;
					background: #FFFFFF;
					border: 1px solid #CCCCCC;
					outline: none;
					.iconfont {
						font-size: 12px;
					}
				}
			}
		}
		.table-grid {
			height: 50px;
			line-height: 50px;
			.columns {
				text-align: center;
				cursor: pointer;
				box-sizing: border-box;
				padding: 0 10px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.pitch-on {
				background-color: @highlight-purple;
				color: #fff;
			}
		}
		//最后一个不需要边框
		>li:nth-last-of-type(1) .table-title-box {
			border-bottom: none;
		}
		>li:nth-last-of-type(1) .rows>div:nth-last-of-type(1) {
			border: none;
		}
	}
</style>
