<template>
  <div class="flex product">
    <div class="flex p-title">
      <!--   2022.1.5 wyb   s     -->
      <div style="display:flex;justify-content:center;align-items:center">
        <span>筛选结果：</span>
        <div class="p-t-right">
          <div class="p-item" :key="index" v-for="(item, index) in screen">
            <span>{{ item.title }}:</span>
            <el-select v-model="screenObj[index]" @change="(id) => { selectChange(id, index) }" clearable
              placeholder="请选择">
              <el-option v-for="son in item.data" :key="son.id" :label="son.categoryName" :value="son.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <div style="display: flex">
          <span style="line-height: 32px">品牌：</span>
          <tree-select
              style="width: 320px;"
              placeholder="请选择品牌"
              v-model="brandId"
              :clearable="false"
              :options="brandList"
              :disable-branch-nodes="true"
              :searchable="true"
              :defaultExpandLevel="10"
              :key="brandId"
              @select="(node) => treeChange(node, 'brandId')"
              :flatten-search-results="true"
          />
        </div>
      </div>
      <div style="display:flex;">
        <ToAddInquiryPage></ToAddInquiryPage>
        <ContactWechat></ContactWechat>
      </div>
      <!--   2022.1.5 wyb   e     -->
    </div>
    <!-- 类别盒子 -->
    <div class="sort-box" ref="sortBox">
      <classify-table :data="list" @onChange="classifyChange" />
    </div>

    <div class="flex-1 flex info-box">
      <!-- 规格筛选 -->
      <div class="card-box info-specification">
        <div class="title">
          <span>规格参数筛选</span>
        </div>
        <el-collapse>
          <el-collapse-item :title="item.specName" :name="index" v-for="(item, index) in specList" :key="index">
            <el-checkbox-group v-model="checkList" @change="checkChange">
              <el-checkbox :label="v.id" v-for="(v, i) in item.specValueResultList" :key="index + '-' + i">
                {{ v.specValue }}
              </el-checkbox>
            </el-checkbox-group>
          </el-collapse-item>
        </el-collapse>
      </div>
      <!-- 产品盒子 -->
      <div class="flex-1 info-goods">
        <!-- 头部筛选 -->
        <div class="flex head">
          <div class="screen flex">
            <div class="flex btn-box">
              <div :class="['btn', 'flex-1', 'flex', listScreen.repertory ? 'btn-check' : '']"
                @click="handleListScreen('repertory')">
                <span>库存</span>
                <span class="iconfont icon-below-s"></span>
              </div>
              <div :class="['btn', 'flex-1', 'flex', listScreen.salesVolume ? 'btn-check' : '']"
                @click="handleListScreen('salesVolume')">
                <span>销量</span>
                <span class="iconfont icon-below-s"></span>
              </div>
              <div class="btn flex-1 flex" @click="handleListScreen('price')">
                <span>价格</span>
                <div class="sort">
                  <span :class="[listScreen.price === 'asc' ? 'sort-check' : '', 'iconfont icon-top-s']"></span>
                  <span :class="[listScreen.price === 'desc' ? 'sort-check' : '', 'iconfont icon-below-s']"></span>
                </div>
              </div>
            </div>
            <div class="total">
              共 <span>{{ totalCount }}</span> 件商品
            </div>
          </div>

          <div class="head-right">
            <div class="c-cart-icon" @click="showQutoe">
              报价<i class="iconfont icon-gouwuche"></i>
              <div class="dot" v-if="quoteGoodsList.length">
                <p>{{ quoteGoodsList.length }}</p>
              </div>
            </div>
            <div class="c-cart-icon" @click="showInquiry">
              <i class="iconfont icon-gouwuche"></i>
              <div class="dot" v-if="cartGoods.length">
                <p>{{ cartGoods.length }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="info-body">
          <!-- 列表 -->
          <lida-table :cutHeight="50" :data="goodsList" border show-summary :summary-method="getSummaries"
            :total="totalCount" :pageSize="pageSize" :page="page" @change="pagingChange" @selection-change="selectGoods"
            ref="table">
            <el-table-column type="selection" width="55" />
            <lida-table-column label="产品信息">
              <template #default="scope">
                <div class="g-info">
                  <el-image :src="scope.row.src" :preview-src-list="[scope.row.src]"
                    style="width: 100px;height: 100px;">
                  </el-image>
                  <div class="g-info-right">
                    <div class="g-info-row g-info-title">{{ scope.row.productName }} |
                      {{ scope.row.seriesName }}
                    </div>
                    <div class="g-info-row">
                      <span>参数:</span>
                      <p>{{ scope.row.barCode }}</p>
                    </div>
                    <div class="g-info-row">
                      <span>订货号:</span>
                      <p>{{ scope.row.articleNo }}</p>
                    </div>
                    <div class="g-info-row">
                      <span>品牌:</span>
                      <p>{{ scope.row.brandName }}</p>
                    </div>
                  </div>
                  <!-- 替换商品 弹窗 -->
                  <el-popover
                    v-if="scope.row.skuReplaceVOList && scope.row.skuReplaceVOList.length > 0"
                    placement="top"
                    width="600"
                    trigger="click">
                    <div class="product-list-box">
                      <p class="p-title">可替换商品</p>
                      <div class="p-list" v-for="(item, index) in scope.row.skuReplaceVOList" :key="index + 'product'">
                        <p class="p-l-title">{{item.productName}}</p>
                        <div class="image-box">
                          <img :src="item.filesUrl" alt="">
                        </div>
                        <div class="info-list">
                          <div class="info-item"><p class="i-label">生产厂家：</p><p class="i-value">{{item.brandName}}</p></div>
                          <div class="info-item"><p class="i-label">面价：</p><p class="i-value">{{item.guidePrice}}</p></div>
                          <div class="info-item"><p class="i-label">参数：</p><p class="i-value">{{item.param}}</p></div>
                          <div class="info-item"><p class="i-label">订货号：</p><p class="i-value">{{item.articleNo}}</p></div>
                          <div class="info-item"><p class="i-label">型号：</p><p class="i-value">{{item.barCode}}</p></div>
                          <div class="info-item"><p class="i-label">系列：</p><p class="i-value">{{item.seriesName}}</p></div>
                        </div>
                        <div class="add-number">
                          <p>数量</p>
                          <el-input-number v-model="item.num" :min="0"></el-input-number>
                        </div>
                      </div>
                      <div class="btn-box">
                        <el-button size="small" type="primary" @click="clickAddCart(scope)">加入购物车</el-button>
                        <el-button size="small" type="primary" @click="clickAddQuoteList(scope)" >加入报价单</el-button>
                      </div>
                    </div>
                    <template #reference>
                      <el-button v-if="scope.row.skuReplaceVOList && scope.row.skuReplaceVOList.length > 0"
                        size="small" class="change-btn" type="danger" plain
                      >替换商品</el-button>
                    </template>
                  </el-popover>
                </div>
              </template>
            </lida-table-column>
            <lida-table-column label="面价">
              <template #default="scope">
                <div>{{ scope.row.guidePrice }}</div>
              </template>
            </lida-table-column>
            <lida-table-column label="数量">
              <template #default="scope">
                <el-input-number v-model="scope.row.num" :min="0" @change="handleChange" />
              </template>
            </lida-table-column>
          </lida-table>
        </div>
      </div>
    </div>
  </div>
  <!-- 询价单弹框 -->
  <inquiry-Model v-model="inquiryShow" :data="cartGoods" @delete="deleteCartGoods" @success="cartSuccess" />
  <!-- 报价弹框 -->
  <quoteModel v-model="qutoeShow" :data="quoteGoodsList" @delete="deleteCartGoods" />
  <!-- 生成链接弹框 -->
  <share-link-dialog v-model="shareLinkShow" :inquiryId="inquiryId" />
</template>
<script>
/**
 * @Date 2021-11-15
 * @Desc 产品选型
 */
import classifyTable from "../components/product/classifyTable.vue";
import inquiryModel from "../components/inquiryModel.vue"; //生成询价单弹框
import { mapActions } from 'vuex';
import {getLetterSortData, handleTreeData, imgUrl} from "../../../common/js/common.js"
import tableMixin from "../mixins/table.js"
import ContactWechat from "@/components/publicComponent/ContactWechat";
import ToAddInquiryPage from "@/components/publicComponent/toAddInquiryPage";

export default {
  components: {
    ContactWechat,
    classifyTable,
    inquiryModel,
    ToAddInquiryPage
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [], //分类列表
      page: 1, //当前页数
      pageSize: 10, //每页显示的数量
      totalCount: 0, //总的商品条数
      goodsList: [],  //商品列表
      specList: [],  //规格列表
      checkList: [],  //当前选中的列表
      ruleForm: { // 头部搜索
        brandId: ""
      },
      specificationCheck: {
        // 规格提交的关键字
        rank: [],
        dianliu: []
      },
      listScreen: { //列表筛选
        repertory: false,
        salesVolume: false,
        price: false,
      },
      checkAll: true, //全选
      screen: [], //筛选内容
      screenObj: [], //筛选结果
      inquiryId: '', // zhd 生成询价单返回的id
      visible: false,
      brandId:undefined,
      brandList:[],
    };
  },
  methods: {
    ...mapActions('purchaseMudule',
      [
        'listSkuCategoryForFirst', //顶级分类请求
        'listSkuCategoryForChildrenOne', //获取子集分类一级
        'skuPlatformPage', //获取商品列表
        'skuSpecList',  //获取规格列表
        'categoryListCategory',  //获取大中小分类
        'listSkuCategoryByCategoryId',//商品类目列表-通过分类查询
      ]),
    getBrandList(){ //获取品牌列表
      this.listSkuCategoryForFirst({
        pageNo: 1,
        pageSize: 99999
      }).then(res => {
        let {
          data,
          code
        } = res.data.data;
        if (code === "0" && data.length) {
          data = getLetterSortData(data, 'initialCode');
          data.map((item, index) => {
            item.id = 'a_' + (index + 1);
            item.label = item.letter;
            if (item.children) {
              item.children.map(son => {
                son.label = son.categoryName;
                delete son.children;
              })
            }
          })
          //默认选中第一个
          this.brandList = data;
          // this.brandId = data[0].children[0].id;
        }
      })
    },
    treeChange(node,type) { //树形选择的事件
      this.brandId=node.id
      let arr=[...this.list[0].children]
      let index=arr.findIndex(v=>v.id==node.id)
      let idx=index+1
      // this.classifyChange(node,index,idx)
      this.getList(this.brandId, index)
      if(this.brandId){
        this.list.splice(0,1)
      }
      this.getData()
    },
    classifyChange(v, index, idx) {
      console.log(v, index, idx)
      if(v.parentId=='0'){
        this.brandId=v.id

      }
      // 类别选中
      this.list[index].children.map(item => item.active = false);
      this.list[index].children[idx].active = !this.list[index].children[idx].active;
      //获取系列
      this.getList(v.id, index);
    },
    handleS(v) {
      // 规格选中
    },
    handleSMore(v) {
      // 规格查看更多
      v.isMore = !v.isMore;
    },
    handleListScreen(v) { // 列表筛选
      if (v === 'price') {
        this.listScreen[v] = this.listScreen[v] === 'asc' ? 'desc' : 'asc'
      } else {
        this.listScreen[v] = !this.listScreen[v]
      }
    },
    getBrand() { //获取品牌
      this.listSkuCategoryForFirst({}).then(res => {
        let {
          code,
          data
        } = res.data;
        if (res.data.code === "0") { //
          this.list = [{
            children: data.data,
            title: "品牌",
          }];
          //请求商品数据
          this.page = 1;
          // this.getData();
        }
      })
    },
    getList(id, index) { //获取系列
      this.listSkuCategoryForChildrenOne({
        parentId: id
      }).then(res => {
        let {
          data,
          code
        } = res.data;
        if (code === "0" && data.data.length) {
          var arr = this.list.slice(0, (index + 1));
          arr.push({
            title: "系列",
            children: data.data,
          })
          this.list = arr;
          /* 请求商品数据 */
          this.page = 1;
          this.checkList = [];  //清空选中的子规格

        }
         this.getData();
        /* 获取规格列表 */
        this.getSpecList();
      })
    },
    getData() { //获取商品列表
      var obj = {};
      this.screenObj.map((item, index) => {
        if (index === 0) {
          obj.categoryOne = item;
        } else if (index === 1) {
          obj.categoryTwo = item;
        } else if (index === 2) {
          obj.categoryThree = item;
        }
      })
      this.skuPlatformPage({
        pageNo: this.page,
        pageSize: this.pageSize,
        seriesId: this.getObj().seriesId,  //系列id
        brandId: this.getObj().brandId?this.getObj().brandId:this.brandId,  //品牌id
        skuSpecIdx: this.checkList,
        ...obj,
      }).then(res => {
        let { code, data } = res.data;
        if (code === "0") {
          this.totalCount = data.totalCount;  //总的数量
          data.rows.map(item => {
            item.num = 1;
            item.src = imgUrl(item);
            // 替换商品窗口数据
            if (item.skuReplaceVOList && item.skuReplaceVOList.length > 0) {
              for (const val of item.skuReplaceVOList) {
                val.num = 1;
                if (val.files) {
                  const files = JSON.parse(val.files)
                  val.filesUrl = files[0] ? files[0].url : ''
                }
              }
            }
          });
          this.goodsList = data.rows;  //绑定数据
          console.log(this.goodsList, '----g')
        }
      })
    },
    getObj() {
      var arr = this.list.filter(v => v.children.some(i => i.active));
      return {
        brandId: this.list.length ?
          this.list[0].children.filter(v => v.active).length ?
            this.list[0].children.filter(v => v.active)[0].id : "" : "",  //品牌id
        seriesId: arr.length > 1 ?
          arr[arr.length - 1].children.filter(v => v.active)[0].id : "", //系列id
      }
    },
    pagingChange(page, pageSize) {  //分页改变的事件
      this.page = page;
      this.pageSize = pageSize;
      this.getData();
    },
    getSpecList() { //获取规格列表
      if (this.getObj().seriesId) {
        this.skuSpecList({
          belongId: this.getObj().seriesId,
          type: "SUBJECT",
        }).then(res => {
          let { code, data } = res.data;
          if (code === "0") {
            this.specList = data;
          }
        })
      }
    },
    showMore(v, index) { //更多的点击事件
      this.list[index] = v;
    },
    checkChange() { //选择的点击事件
      this.page = 1; //请求第一页数据
      this.getData();
    },
    getTypes(id, index) { //获取大中小分类列表
      this.categoryListCategory(id).then(res => {
        let { code, data } = res.data;
        if (code === '0') {
          var title = typeof index === 'number' ? index === 0 ? '大类' :
            index === 1 ? '中类' : index === 2 ? '小类' : '' : '大类';
          var screen = JSON.parse(JSON.stringify(this.screen)),
            obj = JSON.parse(JSON.stringify(this.screenObj));
          ;
          if (typeof index === 'number' && index) {
            screen = screen.slice(0, index);
            obj = obj.slice(0, index);
          }
          if (data.length) {
            if (typeof index === 'number' && screen[index]) {
              screen[index] = { title, data };
            } else {
              if (id || typeof id === 'undefined') {
                screen.push({ title, data });
              }
            }
            this.screen = [...screen];
          }
          this.screenObj = [...obj];
          this.getData();
        }
      })
    },
    selectChange(id, index) {
      var obj = JSON.parse(JSON.stringify(this.screenObj));
      if (obj[index]) {
        obj[index] = id
      } else {
        obj.push(id)
      }
      this.screenObj = [...obj];
      var idx = index + 1;
      this.getTypes(id, idx);

      let newObj={}
      obj.map((item, index) => {
        if (index === 0) {
          newObj.categoryOne = item;
        } else if (index === 1) {
          newObj.categoryTwo = item;
        } else if (index === 2) {
          newObj.categoryThree = item;
        }
      })
      this.listSkuCategoryByCategoryId({
       ...newObj
      }).then(res=>{
        let {
          code,
          data
        } = res.data;
        if (code === "0") { //
          this.list = [{
            children: data.data,
            title: "品牌",
          }];
          //请求商品数据
          this.page = 1;
          this.getData();
        }
      })
    },
    // 替换商品直接加购物车 报价单
		clickAddCart(scope) {
      // console.log(111, scope)
			this.addData('INQUIRY', scope.row.skuReplaceVOList)
		},
		clickAddQuoteList(scope) {
			this.addData('QUOTE', scope.row.skuReplaceVOList)
		}
  },
  created() {
    this.getBrand(); //获取品牌列表
    this.getTypes();   //获取大中小分类列表
    this.getBrandList()
  },
};
</script>
<style lang="less" scoped>
@import "../style/product.less";
</style>
